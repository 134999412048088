import { Component } from "../core/component";

export class HeaderComponent extends Component{
	constructor(id){
		super(id)
	}
	init(){
		if(this.$el){
			const menu = this.$el.querySelector('.menu')
			const header = document.querySelector('header')
			const menuList = header.querySelector('.menu__wrapper')
			const btnClose = header.querySelector('.menu__close')
			const btnBars = document.querySelector('.btn_bars')
			let menufullScreen = document.querySelector('.menu__fullScreen') 
			const items = header.querySelectorAll('.menu__item a')
			const body = document.querySelector('body')
			const submenuWrapper = document.querySelector('.submenuWrapper')
			submenuWrapper
			console.log(btnBars)
			if(header){
				if(btnBars){
				btnBars.addEventListener('click', openMenu.bind(this, menufullScreen, body, submenuWrapper))}
				btnClose.addEventListener('click', closeMenu.bind(this, menufullScreen, body, submenuWrapper))
				let scrollPrev = 0
				// window.addEventListener('scroll', function() {
				// 	let scrolled = window.pageYOffset
				// 	if(/MSIE \d|Trident.*rv:/.test(navigator.userAgent)){
				// 		scrolled = document.documentElement.pageYOffset
				// 	}
				// 	if ( scrolled > 100 && scrolled > scrollPrev ) {
				// 		header.classList.add('out')
				// 	}
				// 	else {
				// 		header.classList.remove('out');
				// 	}
				// 	scrollPrev = scrolled;
				// });
				window.addEventListener('scroll', function(){
					if(window.pageYOffset>111){
						submenuWrapper.style.position='fixed'
					}
					else if (window.pageYOffset<111){
						submenuWrapper.style.position='relative'
					}
				})
				items.forEach(item=>{
					item.addEventListener('click', (event)=>{scrollIntoLocation(event, menuList, btnBars, btnClose, body)})
				})
			}
		}
		
	}
}
const active = ($block) => {
	const active = $block.classList[0]+'_active'
	$block.classList.toggle(active)
}

const openMenu = (menu, body, submenuWrapper) => {
	menu.classList.remove('menu__fullScreen--close')
	if(!menu.classList.contains('.menu__fullScreen--open')){
    menu.classList.add('menu__fullScreen--open')
    body.style.overflow='hidden'
    submenuWrapper.style.position='relative'
	}
	else{
    menu.classList.remove('menu__fullScreen--open')
    body.style.overflow='auto'
    if(window.pageYOffset>114){
      submenuWrapper.style.position='fixed'
    }
	}
}

const closeMenu = (menu, body, submenuWrapper) => {
	menu.classList.remove('menu__fullScreen--open')
  menu.classList.add('menu__fullScreen--close')
  body.style.overflow='auto'
  if(window.pageYOffset>114){
    submenuWrapper.style.position='fixed'
  }
}

const scrollIntoLocation =(event, menuList, btnBars, btnClose, body)=>{
	const link = event.target
	const blockID = link.getAttribute('href').substr(1)
	const menuListActive = document.querySelector(`.${menuList.classList[0]}_active`)
	const btnCloseActive = document.querySelector(`.${btnClose.classList[0]}_active`)
	if(document.getElementById(blockID)){
		event.preventDefault()
		const yOffset = -70; 
		const element = document.getElementById(blockID);
		const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
		window.scrollTo({top: y, behavior: 'smooth'});

	}
	if(menuListActive || btnCloseActive){
		menuList.classList.remove(menuList.classList[0]+'_active')
		btnClose.classList.remove(btnClose.classList[0]+'_active')
		btnBars.classList.remove('menu__btn-bars_delete')
		body.style.overflow='auto'
	}

}