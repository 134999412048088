module.exports = function findHeightMenu () {
	const $menu = document.querySelector('.headerMenu')
	if($menu){
		const getVhMenu = () => $menu.getBoundingClientRect().height
		const setVhMenu = vh => {
			document.documentElement.style.setProperty('--defvhmenu', `${ vh }px`)
		}
		const changeVhMenu  = () => {
			setVhMenu( getVhMenu() )
		}
		changeVhMenu()
		window.addEventListener( 'resize', changeVhMenu )
	}
	const documentHeight = () => {
		const doc = document.documentElement
		doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
	}
	window.addEventListener('resize', documentHeight)
	documentHeight()
	// const $submenu = document.querySelector('.submenuWrapper')
	// if($submenu){
	// 	const getVhSubMenu = () => $submenu.getBoundingClientRect().height
	// 	const setVhSubMenu = vh => {
	// 		document.documentElement.style.setProperty('--defvhsubmenu', `${ vh }px`)
	// 	}
	// 	const changeVhSubMenu  = () => {
	// 		setVhSubMenu( getVhSubMenu() )
	// 	}
	// 	changeVhSubMenu()
	// 	window.addEventListener( 'resize', changeVhSubMenu )
	// }
}
